<template>
    <div class="container">
        <el-form :model="dataForm" ref="dataForm" label-position="top">
            <el-form-item label="上传引擎">
                <el-radio-group v-model="dataForm.engine">
                    <el-radio label="local">本地服务器</el-radio>
                    <el-radio label="aliyun">阿里云(OSS)</el-radio>
                    <el-radio label="tencent">腾讯云(COS)</el-radio>
                </el-radio-group>
            </el-form-item>
            <template v-if="dataForm.engine === 'local'">
                <el-form-item label="图片上传大小限制（M）">
                    <el-input v-model="dataForm.local.image" placeholder="图片上传大小限制，单位：M" type="number"/>
                </el-form-item>
                <el-form-item label="文件上传大小限制（M）">
                    <el-input v-model="dataForm.local.file" placeholder="文件上传大小限制，单位：M" type="number"/>
                </el-form-item>
            </template>
            <template v-else-if="dataForm.engine === 'aliyun'">
                <el-form-item label="图片上传大小限制（M）">
                    <el-input v-model="dataForm.aliyun.image" placeholder="图片上传大小限制，单位：M" type="number"/>
                </el-form-item>
                <el-form-item label="文件上传大小限制（M）">
                    <el-input v-model="dataForm.aliyun.file" placeholder="文件上传大小限制，单位：M" type="number"/>
                </el-form-item>
                <el-form-item label="AccessKey ID">
                    <el-input v-model="dataForm.aliyun.accessKeyId" placeholder="阿里云OSS AccessKey ID" clearable/>
                </el-form-item>
                <el-form-item label="AccessKey Secret">
                    <el-input v-model="dataForm.aliyun.accessKeySecret" placeholder="阿里云OSS AccessKey Secret" clearable/>
                </el-form-item>
                <el-form-item label="存储桶名字">
                    <el-input v-model="dataForm.aliyun.bucket" placeholder="阿里云OOS存储桶名字" clearable/>
                </el-form-item>
                <el-form-item label="存储节点">
                    <el-input v-model="dataForm.aliyun.endpoint" placeholder="阿里云OSS存储节点" clearable/>
                </el-form-item>
            </template>
            <template v-else-if="dataForm.engine === 'tencent'">
                <el-form-item label="图片上传大小限制（M）">
                    <el-input v-model="dataForm.tencent.image" placeholder="图片上传大小限制，单位：M" type="number" clearable/>
                </el-form-item>
                <el-form-item label="文件上传大小限制（M）">
                    <el-input v-model="dataForm.tencent.file" placeholder="文件上传大小限制，单位：M" type="number" clearable/>
                </el-form-item>
                <el-form-item label="SecretId">
                    <el-input v-model="dataForm.tencent.secretId" placeholder="腾讯云COS Secret Id" clearable/>
                </el-form-item>
                <el-form-item label="SecretKey">
                    <el-input v-model="dataForm.tencent.secretKey" placeholder="腾讯云COS Secret Key" clearable/>
                </el-form-item>
                <el-form-item label="存储桶名字">
                    <el-input v-model="dataForm.tencent.bucket" placeholder="腾讯云COS存储桶名字" clearable/>
                </el-form-item>
                <el-form-item label="存储地域">
                    <el-input v-model="dataForm.tencent.region" placeholder="腾讯云COS存储地域" clearable/>
                </el-form-item>
            </template>
            <el-form-item>
                <el-button v-if="$perm('resource_upload_update')" @click="doSave" :loading="loading" type="primary">保 存 配 置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Setting",
    data: function () {
        return {
            loading: false,
            dataForm: {
                engine: 'local',
                local: {
                    image: null,
                    file: null,
                },
                aliyun: {
                    image: null,
                    file: null,
                    accessKeyId: null,
                    accessKeySecret: null,
                    bucket: null,
                    endpoint: null,
                },
                tencent: {
                    image: null,
                    file: null,
                    secretId: null,
                    secretKey: null,
                    bucket: null,
                    region: null
                }
            }
        }
    },
    activated: function () {
        this.getConfig()
    },
    methods: {
        getConfig: function () {
            this.$helper._get(this, 'resource.upload.config', {}, resp => {
                this.dataForm = resp.data
            })
        },
        doSave: function () {
            this.$helper._post(this, 'resource.upload.update', {setting: JSON.stringify(this.dataForm)}, resp => {
                this.$message.success(resp.message)
            })
        }
    }
}
</script>

<style scoped>

</style>
